.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
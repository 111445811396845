.fade-inn {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fade-in h1{
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.custom{
    background-color: #0f77e04b;
    border: none
}

.custom:hover{
    background-color: #0f77e0bd;
    border: none
}
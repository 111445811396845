@media (max-width: 767px) {
    .carousel{
        height:450px;
        width:350px;
        object-Fit: 'cover'; 
    }

    .carousel-item{
        height:450px;
        width:350px;
        object-Fit: 'cover'; 
    }
  }
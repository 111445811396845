.custom{
    background-color: #0f77e04b;
    border: none
}

.custom:hover{
    background-color: #0f77e0bd;
    border: none
}

textarea
{
 resize : none;
}